import { hasSufficientPerformance, hasSufficientHardwareSetup, hasSufficientDownloadPerformance, setup } from 'nuxt-speedkit/utils/performance';
import { isSupportedBrowser } from 'nuxt-speedkit/utils/browser';

let init = false

function initApp() {
  if (init) {
    return
  }
  init = true
  import(/* webpackMode: "eager" */'../client')
};

function initFont() {
  global.document.querySelectorAll('[data-font]').forEach((node) => {
    node.classList.add('font-active');
  })
}

function observeSpeedkitButton (button, callback) {
  if (button) {
    button.addEventListener('click', callback, { capture: true, once: true, passive: true })
  }
}

function updateInfoLayer(item) {
  item.style.display = 'block'
  global.document.querySelector('#nuxt-speedkit__speedkit-layer').className += ' nuxt-speedkit__speedkit-layer--visible';
}

function setupSpeedkitLayer(callback, supportedBrowser) {
  if(!supportedBrowser) {
    updateInfoLayer(global.document.getElementById('nuxt-speedkit__message__unsupported-browser'));
  }
  if(!hasSufficientHardwareSetup()) {
    updateInfoLayer(global.document.getElementById('nuxt-speedkit__message__outdated-device'));
  }
  if(!hasSufficientDownloadPerformance()) {
    updateInfoLayer(global.document.getElementById('nuxt-speedkit__message__slow-connection'));
  }
  observeSpeedkitButton(global.document.getElementById('nuxt-speedkit__button__init-app'), callback);
}

const supportedBrowser = isSupportedBrowser({regex: new RegExp("((CPU[ +]OS|iPhone[ +]OS|CPU[ +]iPhone|CPU IPhone OS)[ +]+(15[_.]6|15[_.]([7-9]|\u005Cd{2,})|15[_.]8|15[_.](9|\u005Cd{2,})|(1[6-9]|[2-9]\u005Cd|\u005Cd{3,})[_.]\u005Cd+|16[_.]6|16[_.]([7-9]|\u005Cd{2,})|(1[7-9]|[2-9]\u005Cd|\u005Cd{3,})[_.]\u005Cd+|17[_.]5|17[_.]([6-9]|\u005Cd{2,})|(1[8-9]|[2-9]\u005Cd|\u005Cd{3,})[_.]\u005Cd+|18[_.]0|18[_.]([1-9]|\u005Cd{2,})|(19|[2-9]\u005Cd|\u005Cd{3,})[_.]\u005Cd+)(?:[_.]\u005Cd+)?)|(Opera Mini(?:\u005C\u002Fatt)?\u005C\u002F?(\u005Cd+)?(?:\u005C.\u005Cd+)?(?:\u005C.\u005Cd+)?)|(Opera\u005C\u002F.+Opera Mobi.+Version\u005C\u002F(80|(8[1-9]|9\u005Cd|\u005Cd{3,}))\u005C.\u005Cd+)|(Opera\u005C\u002F(80|(8[1-9]|9\u005Cd|\u005Cd{3,}))\u005C.\u005Cd+.+Opera Mobi)|(Opera Mobi.+Opera(?:\u005C\u002F|\u005Cs+)(80|(8[1-9]|9\u005Cd|\u005Cd{3,}))\u005C.\u005Cd+)|((?:Chrome).*OPR\u005C\u002F(113|(11[4-9]|1[2-9]\u005Cd|[2-9]\u005Cd\u005Cd|\u005Cd{4,}))\u005C.\u005Cd+\u005C.\u005Cd+)|(SamsungBrowser\u005C\u002F(26|(2[7-9]|[3-9]\u005Cd|\u005Cd{3,}))\u005C.\u005Cd+)|(Edge\u005C\u002F(130|(13[1-9]|1[4-9]\u005Cd|[2-9]\u005Cd\u005Cd|\u005Cd{4,}))(?:\u005C.\u005Cd+)?)|((Chromium|Chrome)\u005C\u002F(109|(11\u005Cd|[2-9]\u005Cd\u005Cd|\u005Cd{4,})|125|(12[6-9]|1[3-9]\u005Cd|[2-9]\u005Cd\u005Cd|\u005Cd{4,})|129|(13\u005Cd|[2-9]\u005Cd\u005Cd|\u005Cd{4,}))\u005C.\u005Cd+(?:\u005C.\u005Cd+)?)|(Version\u005C\u002F(17\u005C.6|17\u005C.([7-9]|\u005Cd{2,})|(1[8-9]|[2-9]\u005Cd|\u005Cd{3,})\u005C.\u005Cd+|18\u005C.1|18\u005C.([2-9]|\u005Cd{2,})|(19|[2-9]\u005Cd|\u005Cd{3,})\u005C.\u005Cd+)(?:\u005C.\u005Cd+)? Safari\u005C\u002F)|(Firefox\u005C\u002F(128|(129|1[3-9]\u005Cd|[2-9]\u005Cd\u005Cd|\u005Cd{4,})|132|(13[3-9]|1[4-9]\u005Cd|[2-9]\u005Cd\u005Cd|\u005Cd{4,}))\u005C.\u005Cd+\u005C.\u005Cd+)|(Firefox\u005C\u002F(128|(129|1[3-9]\u005Cd|[2-9]\u005Cd\u005Cd|\u005Cd{4,})|132|(13[3-9]|1[4-9]\u005Cd|[2-9]\u005Cd\u005Cd|\u005Cd{4,}))\u005C.\u005Cd+(pre|[ab]\u005Cd+[a-z]*)?)", "")});

if (!document.getElementById('nuxt-speedkit__speedkit-layer')) {
  initApp();
} else {
  setup({"device":{"hardwareConcurrency":{"min":2,"max":48},"deviceMemory":{"min":2}},"timing":{"fcp":800,"dcl":1200},"lighthouseDetectionByUserAgent":false});

  setupSpeedkitLayer(initApp, supportedBrowser)

  if(('__NUXT_SPEEDKIT_AUTO_INIT__' in global && global.__NUXT_SPEEDKIT_AUTO_INIT__) || ((true && hasSufficientPerformance()) && supportedBrowser)) {
    initApp();
  }

  observeSpeedkitButton(global.document.getElementById('nuxt-speedkit__button__init-font'), initFont);

  if('__NUXT_SPEEDKIT_FONT_INIT__' in global && global.__NUXT_SPEEDKIT_FONT_INIT__) {
    initFont()
  }
}
