import configVars from "@/config/config_vars";
let appBaseUrl = configVars.appBaseUrl;

import axios from "axios";

import querystring from "querystring";



const getToken = (userWebId, token) => {
  let instance = axios.create();
  instance.defaults.headers.common = {};
  instance.defaults.headers.common["authorization"] = `Bearer ${token}`;

  let url = `${appBaseUrl}api/braintree/getToken/${userWebId}`;
  return instance.get(`${url}`)
}

const processSubscription = (id, token, body) => {
  let instance = axios.create();
  instance.defaults.headers.common = {};
  instance.defaults.headers.common["authorization"] = `Bearer ${token}`;


  let url = `${appBaseUrl}api/braintree/subscription/${id}`;
  return instance.post(`${url}`, body)
}

/**
 *
 * @param {*} id
 * @param {*} token
 */
var createBraintreeCustomer = async (id, token, body) => {

  let instance = axios.create();
  instance.defaults.headers.common = {};
  instance.defaults.headers.common["authorization"] = `Bearer ${token}`;

  let url = `${appBaseUrl}api/braintree/customer/${id}`;
  return instance.post(url, body)
}


const getSubscription = (id, token) => {
  let instance = axios.create();
  instance.defaults.headers.common = {};
  instance.defaults.headers.common["authorization"] = `Bearer ${token}`;


  let url = `${appBaseUrl}api/braintree/subscription/${id}`;
  return instance.get(`${url}`)
}

const getTransaction = (userId, transactionId, token) => {
  let instance = axios.create();
  instance.defaults.headers.common = {};
  instance.defaults.headers.common["authorization"] = `Bearer ${token}`;
  let url = `${appBaseUrl}api/braintree/transaction/${userId}/${transactionId}`;

  return instance.get(`${url}`)
}



/**
 *
 *
 *
 * @param {*} id
 * @param {*} token
 * @param {*} body
 */
const updateSubscription = (id, token, body) => {
  let instance = axios.create();
  instance.defaults.headers.common = {};
  instance.defaults.headers.common["authorization"] = `Bearer ${token}`;


  let url = `${appBaseUrl}api/braintree/subscription/${id}`;
  return instance.put(`${url}`, body)
}


const addNewPaymentMethodToCustomer = (userid, token, body) => {
  let instance = axios.create();
  instance.defaults.headers.common = {};
  instance.defaults.headers.common["authorization"] = `Bearer ${token}`;

  let url = `${appBaseUrl}api/braintree/paymentmethod/${userid}`;
  return instance.post(`${url}`, body)

}

const isSubscriptionActive = (id, token) => {
  let instance = axios.create();
  instance.defaults.headers.common = {};
  instance.defaults.headers.common["authorization"] = `Bearer ${token}`;


  let url = `${appBaseUrl}api/braintree/subscription/active/${id}`;
  return instance.get(`${url}`)
}

const cancelSubscription = (userId, token, subscriptionId) => {
  let instance = axios.create();
  instance.defaults.headers.common = {};
  let url = `${appBaseUrl}api/braintree/subscription/cancel/${userId}/${token}/${subscriptionId}`;
  return instance.post(`${url}`)
}

const sendEmailToUnsubscribe = (userId, token, subscriptionId) => {
  let instance = axios.create();
  instance.defaults.headers.common = {};
  instance.defaults.headers.common["authorization"] = `Bearer ${token}`;
  let url = `${appBaseUrl}api/braintree/subscription/send-email-to-cancel/${userId}/${subscriptionId}`;
  return instance.post(`${url}`)
}

const getCostumer = (userId, token) => {
  let instance = axios.create();
  instance.defaults.headers.common = {};
  instance.defaults.headers.common["authorization"] = `Bearer ${token}`;
  let url = `${appBaseUrl}api/braintree/customer/${userId}`;
  return instance.get(`${url}`)
}



const deletePaymentMethod = (userId, token, paymentMethodToken, customerId) => {
  let instance = axios.create();
  instance.defaults.headers.common = {};
  instance.defaults.headers.common["authorization"] = `Bearer ${token}`;
  let url = `${appBaseUrl}api/braintree/paymentmethod/${userId}/${paymentMethodToken}/${customerId}`;

  return instance.delete(`${url}`)
}



const UpdatePepperCashFromBraintreeSubscriptionsTransactions = (userId, token) => {
  let instance = axios.create();
  instance.defaults.headers.common = {};
  instance.defaults.headers.common["authorization"] = `Bearer ${token}`;
  let url = `${appBaseUrl}api/peppercash/${userId}`;
  return instance.put(`${url}`)
}


const customerAvailablePepperCash = (userId, token) => {
  let instance = axios.create();
  instance.defaults.headers.common = {};
  instance.defaults.headers.common["authorization"] = `Bearer ${token}`;
  let url = `${appBaseUrl}api/peppercash/${userId}`;
  return instance.get(`${url}`)
}


const processPayment = (userId, token, body) => {
  let instance = axios.create();
  instance.defaults.headers.common = {};
  instance.defaults.headers.common["authorization"] = `Bearer ${token}`;
  let url = `${appBaseUrl}api/tevo/payment/${userId}`;
  return instance.post(`${url}`, body)
}





export default {
  getToken,
  getCostumer,
  processSubscription,
  getSubscription,
  isSubscriptionActive,
  createBraintreeCustomer,
  updateSubscription,
  addNewPaymentMethodToCustomer,
  cancelSubscription,
  deletePaymentMethod,
  sendEmailToUnsubscribe,
  getTransaction,
  UpdatePepperCashFromBraintreeSubscriptionsTransactions,
  processPayment,
  customerAvailablePepperCash,
}


