export const state = () => ({
  media_match: 0,
  createNewInvitation: false,
  clickBody: false,
  authToken: '',
  IP: '',
  visto: false,
  follow: false,
  numVistas: 0,
  numVistasHwi: 0,
  numVistasMovil: 0,
  openTrackArtist: false,
  titulo: '',
  coordinates: {},
  GoToAfterSign: null,
  signTitle: "Sign In",
  vipSplashVisto: false,
  openMobileSearch: false,
  openCategoriesMenu: false,
  openCategoryMenu: false,
  magic_flag: false,
  tooglePromoRegister: null,
  showPromoToast: false,
  toogleSignIn: false,
  userPromoAdded: false,
  userPromoSkiped: false,
  zIndexClass: 'z-index-1',
  isSubscriptionActivated: false,
})


export const getters = {
  isSubscriptionActivated(state) {
    return state.isSubscriptionActivated
  },
  zIndexClass(state) {
    return state.zIndexClass
  },
  loginOnPageReload(state) {
    return state.loginOnPageReload
  },
  userPromoAdded(state) {
    return state.userPromoAdded
  },
  userPromoSkiped(state) {
    return state.userPromoSkiped
  },
  toogleSignIn(state) {
    return state.toogleSignIn
  },
  showPromoToast(state) {
    return state.showPromoToast
  },
  tooglePromoRegister(state) {
    return state.tooglePromoRegister
  },
  magic_flag(state) {
    return state.magic_flag
  },
  openCategoryMenu(state) {
    return state.openCategoryMenu
  },
  openCategoriesMenu(state) {
    return state.openCategoriesMenu
  },
  openMobileSearch(state) {
    return state.openMobileSearch
  },
  vipSplashVisto(state) {
    return state.vipSplashVisto
  },
  signTitle(state) {
    return state.signTitle
  },
  GoToAfterSign(state) {
    return state.GoToAfterSign
  },

  coordinates(state) {
    return state.coordinates
  },
  media_match(state) {
    return state.media_match;
  },
  isCreatedNewInvitation(state) {
    return state.createNewInvitation;
  },
  isClickBody(state) {
    return state.clickBody
  },

  authToken(state) {
    return state.authToken
  },
  IP(state) {
    return state.IP
  },
  visto(state) {
    return state.visto
  },
  follow(state) {
    return state.follow
  },
  numVistas(state) {
    return state.numVistas
  },

  numVistasHwi(state) {
    return state.numVistasHwi
  },

  numVistasMovil(state) {
    return state.numVistasMovil
  },


  openTrackArtist(state) {
    return state.openTrackArtist
  },
  titulo(state) {
    return state.titulo
  }
};

export const mutations = {
  isSubscriptionActivated(state, isSubscriptionActivated) {
    state.isSubscriptionActivated = isSubscriptionActivated
  },
  setZIndexClass(state, className) {
    state.zIndexClass = className;
  },
  loginOnPageReload(state, loginOnPageReload) {
    state.loginOnPageReload = loginOnPageReload
  },
  userPromoSkiped(state, userPromoSkiped) {
    state.userPromoSkiped = userPromoSkiped
  },
  userPromoAdded(state, userPromoAdded) {
    state.userPromoAdded = userPromoAdded
  },
  toogleSignIn(state, toogleSignIn) {
    state.toogleSignIn = toogleSignIn
  },
  showPromoToast(state, showPromoToast) {
    state.showPromoToast = showPromoToast
  },


  tooglePromoRegister(state, tooglePromoRegister) {
    state.tooglePromoRegister = tooglePromoRegister
  },
  magic_flag(state, magic_flag) {
    state.magic_flag = magic_flag
  },
  openCategoryMenu(state, openCategoryMenu) {
    state.openCategoryMenu = openCategoryMenu
  },
  openCategoriesMenu(state, openCategoriesMenu) {
    state.openCategoriesMenu = openCategoriesMenu
  },
  openMobileSearch(state, openMobileSearch) {
    state.openMobileSearch = openMobileSearch
  },
  vipSplashVisto(state, vipSplashVisto) {
    state.vipSplashVisto = vipSplashVisto;
  },

  signTitle(state, signTitle) {
    state.signTitle = signTitle
  },

  GoToAfterSign(state, GoToAfterSign) {
    state.GoToAfterSign = GoToAfterSign
  },



  storeCoordinates(state, coordinates) {
    state.coordinates = coordinates
  },
  change_media_match(state, media_match) {
    state.media_match = media_match;
  },

  changeCreateNewInvitation(state, created) {
    state.createNewInvitation = created;
  },
  ClickBody(state, clicked) {
    state.clickBody = clicked
  },

  storeAuthToken(state, token) {
    state.authToken = token
  },
  storeIP(state, IP) {
    state.IP = IP
  },
  storeVisto(state, visto) {
    state.visto = visto
  },
  storeFollow(state, follow) {
    state.follow = follow
  },

  storeNumVistas(state, numVistas) {
    state.numVistas = numVistas
  },

  storeNumVistasHwi(state, numVistasHwi) {
    state.numVistasHwi = numVistasHwi
  },

  storeNumVistasMovil(state, numVistasMovil) {
    state.numVistasMovil = numVistasMovil
  },



  storeOpenTrackArtist(state, openTrackArtist) {
    state.openTrackArtist = openTrackArtist
  },

  storeTitulo(state, titulo) {
    state.titulo = titulo
  },

  storeFollow(state, follow) {
    state.follow = follow
  }


}






export const actions = {
  async nuxtServerInit({ commit }, { req }) {


  }
}
