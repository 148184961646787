/* eslint-disable */
import configVars from "../config/config_vars";
const appBaseUrl = configVars.appBaseUrl;
import axios from "axios";

export const state = () => ({
  ReviewOrder: null,
  activePromoCode: null,
  promo_code: undefined,
  isPercentage: null,
  discountValue: 0,
  promoAddedFromModal: false,
  badPromoInsertedFromModal: false,
  promoAddedFromAddPromoModal: false,
  discount: 0,
  price: 0,
  wholesale_price: 0,
  quantity: 0,
  discountAp: ``,
  total: 0,
  subtotal: 0,
  serviceFee: 0,
  havePromoCodeText: ``,
  showPromoCode: false,
  promoCodeFromUrl: false,
  tax: 0,
  ship_price: 0,
  pendingShippingAndTax: true,
  enableWelcomeDiscount: true,
  selectedTicketGroup: null,
  selectedCheckoutTicketGroup: {},
  isActivePromoBeenSetUp: false,
});

export const getters = {
  isActivePromoBeenSetUp(state) {
    return state.isActivePromoBeenSetUp;
  },
  selectedCheckoutTicketGroup(state) {
    return state.selectedCheckoutTicketGroup;
  },
  selectedTicketGroup(state) {
    return state.selectedTicketGroup;
  },
  enableWelcomeDiscount(state) {
    return state.enableWelcomeDiscount;
  },
  pendingShippingAndTax(state) {
    return state.pendingShippingAndTax;
  },
  subtotal(state) {
    return state.subtotal;
  },
  serviceFee(state) {
    return state.serviceFee;
  },
  ship_price(state) {
    return state.ship_price;
  },
  tax(state) {
    return state.tax;
  },
  promoAddedFromAddPromoModal(state) {
    return state.promoAddedFromAddPromoModal;
  },
  badPromoInsertedFromModal(state) {
    return state.badPromoInsertedFromModal;
  },
  showPromoCode(state) {
    return state.showPromoCode;
  },
  promoCodeFromUrl(state) {
    return state.promoCodeFromUrl;
  },
  havePromoCodeText(state) {
    return state.havePromoCodeText;
  },
  total(state) {
    return state.total;
  },
  discountAp(state) {
    return state.discountAp;
  },
  quantity(state) {
    return state.quantity;
  },
  wholesale_price(state) {
    return state.wholesale_price;
  },
  price(state) {
    return state.price;
  },
  discount(state) {
    return state.discount;
  },
  promoAddedFromModal(state) {
    return state.promoAddedFromModal;
  },
  promo_code(state) {
    return state.promo_code;
  },
  discountValue(state) {
    return state.discountValue;
  },
  isPercentage(state) {
    return state.isPercentage;
  },
  getReviewOrder(state) {
    return state.ReviewOrder;
  },

  activePromoCode(state) {
    return state.activePromoCode;
  },
};

export const mutations = {
  isActivePromoBeenSetUp(state, isActivePromoBeenSetUp) {
    state.isActivePromoBeenSetUp = isActivePromoBeenSetUp;
  },
  selectedCheckoutTicketGroup(state, selectedCheckoutTicketGroup) {
    state.selectedCheckoutTicketGroup = selectedCheckoutTicketGroup
  },
  selectedTicketGroup(state, selectedTicketGroup) {
    state.selectedTicketGroup = selectedTicketGroup;
  },
  enableWelcomeDiscount(state, enableWelcomeDiscount) {
    state.enableWelcomeDiscount = enableWelcomeDiscount;
  },
  pendingShippingAndTax(state, pendingShippingAndTax) {
    state.pendingShippingAndTax = pendingShippingAndTax;
  },
  subtotal(state, subtotal) {
    state.subtotal = subtotal;
  },
  serviceFee(state, serviceFee) {
    state.serviceFee = serviceFee;
  },
  ship_price(state, ship_price) {
    state.ship_price = ship_price;
  },
  tax(state, tax) {
    state.tax = tax;
  },
  promoAddedFromAddPromoModal(state, promoAddedFromAddPromoModal) {
    state.promoAddedFromAddPromoModal = promoAddedFromAddPromoModal;
  },
  badPromoInsertedFromModal(state, badPromoInsertedFromModal) {
    state.badPromoInsertedFromModal = badPromoInsertedFromModal;
  },
  promoCodeFromUrl(state, promoCodeFromUrl) {
    state.promoCodeFromUrl = promoCodeFromUrl;
  },
  showPromoCode(state, showPromoCode) {
    state.showPromoCode = showPromoCode;
  },
  havePromoCodeText(state, havePromoCodeText) {
    state.havePromoCodeText = havePromoCodeText;
  },
  total(state, total) {
    state.total = total;
  },
  discountAp(state, discountAp) {
    state.discountAp = discountAp;
  },
  quantity(state, quantity) {
    state.quantity = quantity;
  },

  wholesale_price(state, wholesale_price) {
    state.wholesale_price = wholesale_price;
  },
  price(state, price) {
    state.price = price;
  },
  discount(state, discount) {
    state.discount = discount;
  },
  promoAddedFromModal(state, promoAddedFromModal) {
    state.promoAddedFromModal = promoAddedFromModal;
  },
  discountValue(state, discountValue) {
    state.discountValue = discountValue;
  },
  isPercentage(state, isPercentage) {
    state.isPercentage = isPercentage;
  },
  promo_code(state, promo_code) {
    state.promo_code = promo_code;
  },
  storeReviewOrder(state, ReviewOrder) {
    state.ReviewOrder = ReviewOrder;
  },
  activePromoCode(state, activePromoCode) {
    state.activePromoCode = activePromoCode;
  },
};

export const actions = {
  // Recalculate the total cost of the order based on various factors
  // including quantity, discounts, service fees, and additional charges
  recalculateTotal({ state, commit, dispatch }, params) {
    console.log(`recalcular total`);
    commit("discount", 0);
    if (state.promo_code == undefined) {
      commit("isPercentage", false);
      commit("discountValue", 0);
    }
    if (state.isPercentage === true) {
      let multiplier = state.quantity;
      if (state.activePromoCode?.onlyAppliesToFirstTicket === true) {
        multiplier = 1;
      }

      let discount =
        (state.wholesale_price * state.discountValue * multiplier) / 100;

      if (
        state.quantity <= 1 &&
        state.activePromoCode.onlyAppliesToFirstTicket === true
      ) {
        discount = 0;
      }

      commit("discount", Number(discount));
      let discountAp = `${state.discountValue} % off applied`;
      commit("discountAp", discountAp);
      console.log(state.discountAp);
    } else {
      let discount = state.discountValue;
      commit("discount", Number(discount));
      this.discountAp = `${state.discount} USD applied`;
    }
    if (state.discountValue == 0) {
      let discount = 0;
      commit("discount", Number(discount));
      let discountAp = `Have a promo code?`;
      commit("discountAp", discountAp);
    }

    const roundedDiscount = (state.discount * 100 + Number.EPSILON) / 100;

    const subtotal =
      Math.round(
        (state.wholesale_price * state.quantity - roundedDiscount) * 100 +
        Number.EPSILON
      ) / 100;

    const serviceFee =
      Math.round(
        (state.wholesale_price * 0.21 + 0.01) * state.quantity * 100 +
        Number.EPSILON
      ) / 100;

    const total =
      Math.round(
        (subtotal + serviceFee + state.ship_price + state.tax) * 100 +
        Number.EPSILON
      ) / 100;

    commit("serviceFee", serviceFee);
    commit("subtotal", subtotal);
    commit("total", total);

    console.log(`----`);
    console.log(state.total);
    console.log(state.price);
    console.log(state.quantity);
    console.log(state.discount);
  },

  //// Reset the promo code-related state values to their initial state.
  resetCheckoutPromoCode({ commit, dispatch }, params) {
    console.log("resetCheckoutPromoCode");

    commit("promo_code", undefined);
    commit("isPercentage", null);
    commit("discountValue", 0);
    commit("discount", 0);
    commit("isActivePromoBeenSetUp", false);
    commit("promoAddedFromModal", false);

    let body = {};
    body.code = "peppertixpromo";

    dispatch("verifyPromoCode", body)
    console.log(state.promo_code);
  },

  /**
   * Set the review order information in the Vuex store.
  * @param {*} param0 - Vuex context object containing commit and dispatch methods.
  * @param {*} ReviewOrder - The review order information to be stored.
  */
  setReviewOrder({ commit, dispatch }, ReviewOrder) {
    commit("storeReviewOrder", ReviewOrder);
  },

  /**
    *
    * @param {*} params - Parameters to be included in the POST request.
    * @returns {Promise} - A Promise that resolves with the response or rejects with an error.
    * @description Sends a request to http://localhost:8888/checkout/promo to apply a promo code.
  */
  checkoutPromo({ commit, dispatch }, params) {
    let checkoutPromoSearch = `${appBaseUrl}checkout/promo`;
    return new Promise((resolve, reject) => {
      let instance = axios.create();
      instance.defaults.headers.common = {};
      // instance.defaults.headers.common["authorization"] = `Bearer ${configVars.API_REST_SECRET}`;
      instance
        .post(`${checkoutPromoSearch}`, params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Retrieve promo information by sending a POST request to the promo endpoint.
   * @param {*} param0 - Vuex context object containing commit and dispatch methods.
   * @param {*} params - Parameters to be included in the POST request.
   * @returns {Promise} - A Promise that resolves with the response or rejects with an error.
   * @description Sends a request to http://localhost:3069/checkout/get_promo to retrieve promo information.
   */
  getPromo({ commit, dispatch }, params) {
    let checkoutPromoSearch = `${appBaseUrl}checkout/get_promo`;
    return new Promise((resolve, reject) => {
      let instance = axios.create();
      instance.defaults.headers.common = {};
      // instance.defaults.headers.common["authorization"] = `Bearer ${configVars.API_REST_SECRET}`;
      instance
        .post(`${checkoutPromoSearch}`, params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Asynchronously retrieve promo information from the database by sending a POST request.
   * @param {*} param0 - Vuex context object containing commit and dispatch methods.
   * @param {*} params - Parameters to be included in the POST request.
   * @returns {Promise} - A Promise that resolves with the response or an empty object if the request fails.
   * @description Sends a request to http://localhost:3069/checkout/get_promo_bd to retrieve promo information from the database.
   */
  async getPromoBD({ commit, dispatch }, params) {
    let checkoutPromoSearch = `${appBaseUrl}checkout/get_promo_bd`;

    let instance = axios.create();
    instance.defaults.headers.common = {};
    // instance.defaults.headers.common["authorization"] = `Bearer ${configVars.API_REST_SECRET}`;

    let response = {};
    try {
      response = await instance.post(`${checkoutPromoSearch}`, params);
    } catch { }

    return response;
  },

  /**
   * Verify and apply a promo code by making asynchronous requests to the promo database and promo endpoint.
   * @param {*} param0 - Vuex context object containing commit, dispatch, and state methods.
   * @param {*} rootState - The root state object of the Vuex store.
   * @param {*} params - Parameters to be included in the POST request.
   * @description Performs the following steps:
   * 1. Sends a POST request to http://localhost:3069/checkout/get_promo_bd to retrieve promo information from the database.
   * 2. If successful, constructs a new request and sends it to http://localhost:3069/checkout/get_promo to verify the promo code.
   * 3. If the code is valid and applicable, updates the active promo code in the state and triggers recalculation of the total.
   */
  async verifyPromoCode({ state, commit, dispatch, rootState }, params) {
    let instance = axios.create();
    instance.defaults.headers.common = {};
    // instance.defaults.headers.common["authorization"] = `Bearer ${configVars.API_REST_SECRET}`;

    let promoResponse = {};
    let promoResponseBD = {};

    let promoCodeFromUrl = false;
    if (params.promoCodeFromUrl === true) {
      promoCodeFromUrl = true;
      delete params.promoCodeFromUrl;
    }

    try {
      promoResponseBD = await instance.post(
        `${appBaseUrl}checkout/get_promo_bd`,
        params
      );
    } catch {
      if (state.promoAddedFromAddPromoModal == true) {
        commit("badPromoInsertedFromModal", true);
        commit("promoAddedFromAddPromoModal", false);
        return;
      }
      dispatch("resetCheckoutPromoCode");
      commit("activePromoCode", null);
      console.log("1.0: activePromoCode has been set to null after a failed promoResponseBD request");
      return;
    }

    let newparams = {};
    if (!promoResponseBD.data) {
      if (state.promoAddedFromAddPromoModal == true) {
        commit("badPromoInsertedFromModal", true);
        commit("promoAddedFromAddPromoModal", false);
        return;
      }

      dispatch("resetCheckoutPromoCode");
      commit("activePromoCode", null);
      console.log("1.1: activePromoCode has been set to null because promoResponseBD.data is falsy");
      return;
    }
    if (!promoResponseBD.data.code) {
      if (state.promoAddedFromAddPromoModal == true) {
        commit("badPromoInsertedFromModal", true);
        commit("promoAddedFromAddPromoModal", false);
        return;
      }

      dispatch("resetCheckoutPromoCode");
      commit("activePromoCode", null);
      console.log("1.2: activePromoCode has been set to null because promoResponseBD.data.code is falsy");
      return;
    }

    let code = promoResponseBD.data.code;
    const bannerUrl = promoResponseBD.data.bannerUrl;
    const name = promoResponseBD.data.name;

    newparams = {
      promo_code: code,
    };

    try {
      promoResponse = await instance.post(
        `${appBaseUrl}checkout/get_promo`,
        newparams
      );
    } catch {
      if (state.promoAddedFromAddPromoModal == true) {
        commit("badPromoInsertedFromModal", true);
        commit("promoAddedFromAddPromoModal", false);
        return;
      }

      dispatch("resetCheckoutPromoCode");
      commit("activePromoCode", null);
      console.log("1.3: activePromoCode has been set to null after a failed promoResponse request");
      return;
    }

    code = promoResponse.data.code;
    let value = promoResponse.data.discountValue;

    if (code != undefined && value != undefined && value > 0) {
      if (promoResponse.data.enddate) {
        const now = new Date();
        const enddate = new Date(promoResponse.data.enddate);
        if (enddate >= now) {
          promoResponse.data.name = name;
          promoResponse.data.bannerUrl = bannerUrl;

          let activePromoCode = { ...promoResponse.data };
          let activePromoCodeDB = { ...promoResponseBD.data };

          activePromoCode.message = activePromoCodeDB.message;
          activePromoCode.title2 = activePromoCodeDB.title2;
          activePromoCode.sectionTitle = activePromoCodeDB.sectionTitle;
          activePromoCode.mobile = activePromoCodeDB.mobile;
          activePromoCode.desktop = activePromoCodeDB.desktop;
          activePromoCode.onlyAppliesToFirstTicket =
            activePromoCodeDB.onlyAppliesToFirstTicket;

          commit("activePromoCode", activePromoCode);
          commit("promoCodeFromUrl", promoCodeFromUrl);
        } else {
          if (state.promoAddedFromAddPromoModal == true) {
            commit("badPromoInsertedFromModal", true);
            commit("promoAddedFromAddPromoModal", false);
            return;
          }

          dispatch("resetCheckoutPromoCode");
          commit("activePromoCode", null);
          console.log("1.4: activePromoCode has been set to null because the promo code has expired");
        }
      } else {
        if (state.promoAddedFromAddPromoModal == true) {
          commit("badPromoInsertedFromModal", true);
          commit("promoAddedFromAddPromoModal", false);
          return;
        }

        dispatch("resetCheckoutPromoCode");
        commit("activePromoCode", null);
        console.log("1.5: activePromoCode has been set to null because enddate is missing");
      }
    } else {
      if (state.promoAddedFromAddPromoModal == true) {
        commit("badPromoInsertedFromModal", true);
        commit("promoAddedFromAddPromoModal", false);
        return;
      }

      dispatch("resetCheckoutPromoCode");
      commit("activePromoCode", null);
      console.log("1.6: activePromoCode has been set to null because the promo code is invalid or has no value");
    }

    if (
      (state.activePromoCode && state.promoAddedFromModal) ||
      (state.activePromoCode && state.activePromoCode.code && rootState.user.user)
    ) {
      commit("promo_code", state.activePromoCode.code);
      commit("isPercentage", state.activePromoCode.isPercentage);
      commit("discountValue", state.activePromoCode.discountValue);
    } else {
      commit("promo_code", undefined);
      commit("isPercentage", false);
      commit("discountValue", 0);
    }

    dispatch("recalculateTotal");
  }
  ,

  /**
   * Initialize the payment process by making a POST request to the payment endpoint.
   * @param {*} param0 - Vuex context object containing commit, dispatch, and state methods.
   * @param {*} params - Parameters to be included in the POST request.
   * @description Performs the following steps:
   * 1. Constructs the payment endpoint URL using the appBaseUrl.
   * 2. Creates a new instance of Axios for making the HTTP request.
   * 3. Sends a POST request to the payment endpoint with the provided parameters.
   * 4. Resolves the promise with the response if successful, otherwise rejects with an error.
   */
  initPay({ commit, dispatch }, params) {
    let pay = `${appBaseUrl}pay`;
    return new Promise((resolve, reject) => {
      let instance = axios.create();
      instance.defaults.headers.common = {};
      // instance.defaults.headers.common["authorization"] = `Bearer ${configVars.API_REST_SECRET}`;
      instance
        .post(`${pay}`, params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Finalize the credit card payment by making a POST request to the payment completion endpoint.
   * @param {*} param0 - Vuex context object containing commit, dispatch, and state methods.
   * @param {*} params - Parameters to be included in the POST request.
   * @description Performs the following steps:
   * 1. Constructs the payment completion endpoint URL using the appBaseUrl.
   * 2. Creates a new instance of Axios for making the HTTP request.
   * 3. Sends a POST request to the payment completion endpoint with the provided parameters.
   * 4. Resolves the promise with the response if successful, otherwise rejects with an error.
   */
  finishCC({ commit, dispatch }, params) {
    let pay = `${appBaseUrl}finish_pay_credit_card/`;
    return new Promise((resolve, reject) => {
      let instance = axios.create();
      instance.defaults.headers.common = {};
      // instance.defaults.headers.common["authorization"] = `Bearer ${configVars.API_REST_SECRET}`;
      instance
        .post(`${pay}`, params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Initiate a PayPal payment by making a POST request to the PayPal payment endpoint.
   * @param {*} param0 - Vuex context object containing commit, dispatch, and state methods.
   * @param {*} params - Parameters to be included in the POST request.
   * @description Performs the following steps:
   * 1. Constructs the PayPal payment endpoint URL using the appBaseUrl.
   * 2. Creates a new instance of Axios for making the HTTP request.
   * 3. Sends a POST request to the PayPal payment endpoint with the provided parameters.
   * 4. Resolves the promise with the response if successful, otherwise rejects with an error.
   */
  paypalPay({ commit, dispatch }, params) {
    let pay = `${appBaseUrl}checkout/paypal_pay/`;
    return new Promise((resolve, reject) => {
      let instance = axios.create();
      instance.defaults.headers.common = {};
      //// instance.defaults.headers.common["authorization"] = `Bearer ${configVars.API_REST_SECRET}`;
      instance
        .post(`${pay}`, params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
